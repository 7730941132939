import React, { Suspense, useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ContentWrapper } from "./styles";
import Loader from "components/Loader";
import Header from "components/Header";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css"; // Ensure styles are loaded
import Footer from "components/Footer";
import AwardForm from "pages/AwardForm";
import AwardList from "pages/AwardList";
import "aos/dist/aos.css";
import AwardMap from "pages/Maps";


// Lazy-loaded Pages
const HomePage = React.lazy(() => import("pages/Homepage"));
const About = React.lazy(() => import("pages/About"));
const Founder = React.lazy(() => import("pages/About/Founder"));
const Contact = React.lazy(() => import("pages/Contact"));
const Policy = React.lazy(() => import("pages/StaticPages/Policy"));
const Subscription = React.lazy(() => import("pages/Subscription"));
const Settings = React.lazy(() => import("pages/Settings"));
const HelpAndSupport = React.lazy(() => import("pages/HelpAndSupport"));
const AppDetail = React.lazy(() => import("pages/About/AppDetail"));

const AppRouter = () => {
  return (
    <BrowserRouter>
      <InnerAppRouter />
    </BrowserRouter>
  );
};

const InnerAppRouter = () => {
  const location = useLocation();
  const scrollRef = useRef(null);

  useEffect(() => {

    console.log("location.pathname", location.pathname)
    let scroll: LocomotiveScroll | null = null;

    if (scrollRef.current) {
      scroll = new LocomotiveScroll({
        el: scrollRef.current,
        smooth: true,
        lerp: 0.1, // Adjust for smoother animations
        multiplier: 1, // Adjust scrolling speed
      });

      scroll.update(); // Ensure the scroll instance is updated
    }

    return () => {
      if (scroll) {
        scroll.destroy(); // Cleanup on unmount
      }
    };
  }, [location.pathname]); // Runs on route changes

  return (
    <div className="__dark">
      <Header />
      <ContentWrapper id="smooth-wrapper" >
        {location.pathname !== '/home' && location.pathname !== '/' && <div style={{background:"#cda55614", height : '18vh', width:'100%', marginBottom:'15px'}}></div>}
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/about/app/:appId" element={<AppDetail />} />
            <Route path="/founder" element={<Founder />} />
            <Route path="/privacy" element={<Policy id="59" />} />
            <Route path="/cookie" element={<Policy id="46" />} />
            <Route path="/terms" element={<Policy id="47" />} />
            <Route path="/subscribe" element={<Subscription />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/help-and-support" element={<HelpAndSupport />} />
            <Route path="/award-form" element={<AwardForm />} />
            <Route path="/award-info" element={<Policy id="57" />} />
            <Route path="/nominee-condition" element={<Policy id="56" />} />
            <Route path="/awards" element={<AwardList />} />
            <Route path="/maps" element={<AwardMap />} />

            <Route path="*" element={<HomePage />} /> {/* 404 fallback */}
          </Routes>
        </Suspense>
      </ContentWrapper>
      <Footer />
    </div>
  );
};

export default AppRouter;

