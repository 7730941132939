import { notification } from "antd";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const validateEmail = (input: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
};

export const pluralize = (count: number, key: string) => {
  return `${key}${count > 1 ? "s" : ""}`;
};

export const openNotificationWithIcon = (message: string, type?: NotificationType, title?: string) => {
  notification[type || "error"]({
    message: title || "Error",
    description: message || 'Something went Wrong',
  });
};

export const getFilteredObject = (obj: any) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== '')
  );
}

export const formatDate = (date: string) => {
  const options: any = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(date).toLocaleDateString('en-US', options);
};

export const hasUpperCase = (str: string) => /[A-Z]/.test(str);

export const getPasswordError = (value: string) => {
  let passwordError: string = "";
  const str = value?.trim()
  if (str.length < 8) {
    passwordError = "Password must be 8 characters or longer!";
  } else if (!hasUpperCase(str)) {
    passwordError = "Please use at least one capital letter!";
  } else if (!hasNumberOrSpecialChar(str)) {
    passwordError = "Please use at least one number or special character!";
  }

  return passwordError;
}

export const hasNumberOrSpecialChar = (str: string) => /[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(str);