export const colors = {
    primary: '#593d2b',
    secondary:'#cda556',
    darkPrimary: '#2f2016',
    primaryText: '#222222',
    error: '#FF4D4F',
    success: '#52C41A',
    orange: 'rgba(250, 187, 5, 0.28)',
    secondaryText: '#666666',
    grey1: '#888888',
    grey2: '#E1E1E1',
    grey3: '#F9F9F9',
    grey4: '#F1F1F1',
    grey5: '#d9d9d9',
    grey6: '#7C7C7C',
    border: '#9b8d71',
}