import styled from "styled-components";

export const Wrapper = styled.div<any>`
    position: relative;
    min-height: 100vh;
    width: 100%;
    direction: ${(props) => props.direction};

    input {
        padding: 10px 20px;
        height: 45px;
    }

    .ant-select {
        height: 45px;
    }

    .ant-upload-list-item {
        width: max-content;
    }

    .ant-form-item-explain-error {
        font-size: 12px;
        margin: 2px 0;
    }
`;
