import { colors } from "assets/styles/colors";
import { FontSizes } from "utils/enums";
import styled from "styled-components";
import BackgroundBanner from "assets/images/banner_large.jpg";
import BackBannerNice from "assets/images/banner_img.jpg";

export const HomepageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;

    --left: 20px; /* Define the --left variable here */

    .absolute {
        position: absolute;
    }

   

    .__image_size {
        width: 170px;
        height: 170px;
        object-fit: cover;
        object-position: center;
        border: 2px solid #fff;
        padding: 7px;
        background: #fff;
        cursor: pointer;
        transition: 0.4s;

        &:hover {
            scale: 1.05;
            z-index: 1111;
        }

        @media (max-width: 1300px) {
            width: 150px;
            height: 150px;
        }

        @media (max-width: 968px) {
            width: 130px;
            height: 130px;
        }

        @media (max-width: 700px) {
            width: 110px;
            height: 110px;
            border: 1px solid #fff;
            padding: 3px;
        }

        @media (max-width: 550px) {
            width: 90px;
            height: 90px;
        }
    }

    @media (max-width: 968px) {
        .__ninesixeight1 {
            top: 14% !important;
            left: 56% !important;
        }
            .__ninesixeight2 {
           bottom:23% !important;
        }
        
    }
`;

export const Banner = styled.div`
    position: relative;
    background-image: url(${BackBannerNice});
    height: 90vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 60px;
    padding-left: 13%;
    background-position: center;
    overflow:hidden;
    @media (max-width: 550px) {
            padding-left:3%;
        }

    h1 {
        font-size: 25px;
        text-align: left;
        font-weight: 400;
         @media (max-width: 550px) {
            font-size:16px;
        }
    }
`;

export const BannerStrip = styled.div`
    height: 100px;
    width: 100%;
    background-color: #e9d49d;
    color: ${colors.primaryText};
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px 40px;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    h2 {
        margin: 0;
        font-size: 1.5em;
        z-index: 1;
    }

    button {
        background-color: ${colors.primary};
        color: #fff;
        border: none;
        padding: 8px 22px;
        text-transform: uppercase;
        z-index: 1;
    }

    img {
        position: absolute;
        mix-blend-mode: soft-light;
        opacity: 0.4;
        width: 100%;
        z-index: 0;
    }
`;

export const AboutSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    align-items: center;
    min-height: 70vh;
    text-align: center;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        text-align: left;
    }

    p {
        line-height: 2;
        text-align: justify;
    }

    button {
        background-color: ${colors.secondary};
        color: #fff;
        border: none;
        padding: 8px 16px;
        text-transform: uppercase;
        z-index: 1;
        margin: 20px 0;
    }
`;

export const WinningTitle = styled.div`
    width: 100%;
    min-height: 70vh;
    background: ${colors.grey3};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const WinningCard = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    line-height: 2;


    .book {
        width: 140px;
        height: 200px;
        position: relative;
        perspective: 1000px;
        min-width: 140px;
    }

    .book .cover {
        width: 100%;
        height: 100%;
        background: #fff;
        background-image: url(${BackgroundBanner});
        background-size: cover;
        background-position: center;
        border-radius: 5px;
        position: absolute;
        transform: rotateY(19deg);
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
    }

    .book::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -8px;
        width: 14px;
        height: 204px;
        background: linear-gradient(to right, #bb9c5b, #f2e2c0);
        border-radius: 3px 0 0 3px;
        transform: rotateY(28deg);
        box-shadow: 8px 6px 10px rgba(0, 0, 0, 0.3);
    }

    .book::after {
        content: "";
        position: absolute;
        top: 4px;
        right: -4px;
        width: 10px;
        height: 195px;
        background: linear-gradient(to right, #fff, #ddd);
        border-radius: 0 3px 3px 0;
        transform: rotateY(10deg);
        box-shadow: inset 3px 0 5px rgba(0, 0, 0, 0.2);
    }

    .__content {
        p {
            color: ${colors.secondary};
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height:1.5;

           
        }
        .__author{
            color: ${colors.secondaryText};
            margin-bottom: 5px;
        }
    }
        label{
        display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
`;

export const AwardSetting = styled.div`
    width: 100%;
    min-height: 70vh;

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
    }

    .__award_lists {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
    }

    .__single_award {
        text-align: center;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        z-index: 1;
        position: relative;
        margin-right: 30px;
        width: 150px;

        .ant-avatar {
            width: 40px;
            height: 40px;
        }

        &:after {
            width: 30px;
            height: 1px;
            background: #fff;
            content: "";
            position: absolute;
            right: -30px;
        }

        &:last-child {
            margin-right: 0px;

            &:after {
                display: none;
            }
        }

        h1 {
            font-size: 17px;
            margin: 10px 0;
            min-height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1 1 0%;
            line-height: 1.5;
        }

        .__round {
            width: 16px;
            height: 16px;
            background-color: ${colors.primary};
            border-radius: 10px;
        }

        .__dashed {
            height: 110px;
            border-left: 2px dashed ${colors.primary};
        }

        .__year {
            width: 140px;
            background: ${colors.secondary};
            box-shadow: 0px 0px 10px #0004;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 40px 0;
            font-size: 19px;
            font-weight: 600;
            margin: 30px 0;
            color: #fff;
            line-height: 1;
            padding-top: 3px;
        }

        .imgss {
            width: 140px;
            height: 240px;
            object-fit: cover;
            border-radius: 30px;
        }

        .__category {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1 1;
            font-size: 13px;
            position: absolute;
            top: 23px;
            right: -13px;
            background: #d30505;
            color: #fff;
            padding: 4px 10px;
            border-radius: 10px 0;
            box-shadow: 0px 0px 10px #0004;
            text-align: center;
            width: 100%;
            word-break: break-all;
        }

        .__category_bot {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1 1;
            font-size: 13px;
            position: absolute;
            bottom: 23px;
            left: -13px;
            background: #d30505;
            color: #fff;
            padding: 4px 10px;
            border-radius: 10px 0;
            box-shadow: 0px 0px 10px #0004;
            text-align: center;
            width: 100%;
            word-break: break-all;
        }
    }

    .__award_button {
        border-radius: 50px;
        height: 43px;
        width: 43px;
        border: none;
        color: #555;
        background: #fff;
        box-shadow: 0px 0px 10px #0003;
        transition: 0.4s;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            box-shadow: 0px 0px 10px #0006;
        }
    }
`;

export const NewsMedia = styled.div`
    width: 100%;
    min-height: 70vh;
    background: ${colors.grey3};
    padding: 50px 0;

    .__news_card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        padding: 25px;
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 4px;
        transition: 0.4s;
        border: 2px solid ${colors.secondary};

        h1 {
            font-size: ${FontSizes.default}rem;
            color: ${colors.secondary};
            margin: 20px 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            min-height: 38px;
            justify-content: center;
            align-items: center;
        }

        p {
            font-size: ${FontSizes.tableAction}rem;
            color: ${colors.grey1};
            line-height: 1.5;
            text-align: justify;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;
