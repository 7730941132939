import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const FounderWrapper = styled.div<any>`
    position:relative;
    min-height:100vh;
    direction:${(props) => props.direction};
    width:100%;
    
`
export const AwardLists = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;

    .__dummy {
        width: 240px;
        border: 2px dashed #eee;
        border-radius: 30px;
        padding: 18px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff8e8;
    }
`


export const YearHead = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin-left:-8px;
margin-bottom:20px;

.__sec_hd {
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 30px;
        position: relative;

        &::after {
            background: ${colors.secondary};
            border-left: 14px solid ${colors.darkPrimary};
            border-radius: 30px;
            bottom: -10px;
            content: "";
            height: 7px;
            left: 0;
            position: absolute;
            width: 60px;
        }
}

button{
            background: ${colors.secondary};
            padding:8px 20px;
            border-radius:20px;
            border:none;
            color:#fff;
            font-weight:500;
}

`