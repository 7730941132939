import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, Upload, Alert, message as MSG, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./styles";
import { motion } from "framer-motion";
import { Label } from "assets/styles/globalStyles";
import { FontSizes, FontWeight } from "utils/enums";
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { colors } from "assets/styles/colors";
const { Option } = Select;

const AwardForm: React.FC = () => {
    const { currentLang } = useSelector((store: any) => store.commonData);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState<{ msg: string; open: boolean; severity: "success" | "error" }>({ msg: '', open: false, severity: "success" });
    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);
    const [countries, setCountries] = useState<any[]>([]);

    useEffect(() => {
        fetchCountry();
    }, []);

    const fetchCountry = async () => {
        const res = await fetchData(restAPIs.countryInfos(currentLang?.id));        
        setCountries(res.country)
    }

    const submitForm = async (values: any) => {

        if (!checked) {
            MSG.error("Please agree to the terms of nomination for the award.");
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        formData.append('action', 'contact');
        formData.append('literaryname', values.literaryname);
        formData.append('manuscriptname', values.manuscriptname);
        formData.append('nomineename', values.nomineename);
        formData.append('phone', values.phone);
        formData.append('email', values.email);
        formData.append('nationality', values.nationality);
        formData.append('countryofresidence', values.countryofresidence);
        formData.append('description', values.description);
        formData.append('biography', values.biography);
        formData.append('literaryFile', values.literaryFile.file);
        formData.append('idFile', values.identityFile.file);
        formData.append('nomineePhotoFile', values.nomineePhotoFile.file);

        try {
            const res = await fetchData(restAPIs.nominationForm(formData));
            if (res.statusCode === 200) {
                form.resetFields();
                setErrorAlert({ msg: 'Form submitted successfully', open: true, severity: 'success' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', open: false, severity: 'success' });
                }, 3000);
            } else {
                setErrorAlert({ msg: 'Failed to submit the form.', open: true, severity: 'error' });
            }
        } catch (error) {
            setErrorAlert({ msg: 'An error occurred while submitting the form.', open: true, severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Wrapper direction={currentLang === "ar" ? "rtl" : "ltr"}>
            <div className="container">
                <div className="row justify-content-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }} className="col-md-8">
                        <Label className="fade-left" fontSize={FontSizes.h1} fontWeight={FontWeight.bold} marginTop={0} marginBottom={5}>Submit your entry for the prize</Label>
                        <Label className="fade-left" fontSize={FontSizes.h4} marginTop={0} marginBottom={50}>
                            Please fill out the form below to submit your entry for the award. Ensure all fields are completed accurately.
                        </Label>
                    </motion.div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={submitForm}
                                colon={true}
                                requiredMark="optional"
                            >
                                <Form.Item
                                    name="literaryname"
                                    label="Literary Name"
                                    rules={[{ required: true, message: "Please enter the literary name" }]}
                                >
                                    <Input placeholder="Literary Name" />
                                </Form.Item>

                                <Form.Item
                                    name="manuscriptname"
                                    label="Manuscript Name"
                                    rules={[{ required: true, message: "Please enter the manuscript name" }]}
                                >
                                    <Input placeholder="Manuscript Name" />
                                </Form.Item>

                                <Form.Item
                                    name="nomineename"
                                    label="Nominee Name"
                                    rules={[{ required: true, message: "Please enter the nominee name" }]}
                                >
                                    <Input placeholder="Nominee Name" />
                                </Form.Item>

                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[
                                        { required: true, message: "Please enter your phone number" },
                                        { pattern: /^[0-9]+$/, message: "Phone number must contain only numbers" },
                                    ]}
                                >
                                    <Input placeholder="Phone" />
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { required: true, message: "Please enter your email" },
                                        { type: "email", message: "Please enter a valid email" },
                                    ]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>

                                <Form.Item
                                    name="nationality"
                                    label="Nationality"
                                    rules={[{ required: true, message: "Please select your nationality" }]}
                                >
                                    <Select placeholder="Select Nationality">
                                        {countries.map((country) => (
                                            <Option key={country.value} value={country.value}>
                                                {country.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="countryofresidence"
                                    label="Country of Residence"
                                    rules={[{ required: true, message: "Please select your country of residence" }]}
                                >
                                    <Select placeholder="Select Country of Residence">
                                        {countries.map((country) => (
                                            <Option key={country.value} value={country.value}>
                                                {country.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="description"
                                    label="Description"
                                    rules={[{ required: true, message: "Please enter a description" }]}
                                >
                                    <Input.TextArea placeholder="Description" rows={4} />
                                </Form.Item>

                                <Form.Item
                                    name="biography"
                                    label="Biography"
                                    rules={[{ required: true, message: "Please enter a biography" }]}
                                >
                                    <Input.TextArea placeholder="Biography" rows={4} />
                                </Form.Item>

                                <Form.Item
                                    name="literaryFile"
                                    label="Manuscript (PDF / Word)"
                                    rules={[{ required: true, message: "Please upload your manuscript" }]}
                                >
                                    <Upload
                                        accept=".pdf,.doc,.docx"
                                        beforeUpload={() => false}
                                        multiple={false}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload Manuscript</Button>
                                    </Upload>
                                </Form.Item>

                                <Form.Item
                                    name={"identityFile"}
                                    label="Passport or Identity Card"
                                    rules={[{ required: true, message: "Please upload your passport or identity card" }]}
                                >
                                    <Upload
                                        accept=".jpg,.jpeg"
                                        beforeUpload={() => false}
                                        multiple={false}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload Identity</Button>
                                    </Upload>
                                </Form.Item>

                                <Form.Item

                                    name={"nomineePhotoFile"}
                                    label="Personal Photo"
                                    rules={[{ required: true, message: "Please upload your personal photo" }]}
                                >
                                    <Upload
                                        accept=".jpg,.jpeg"
                                        beforeUpload={() => false}
                                        multiple={false}
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload Photo</Button>
                                    </Upload>
                                </Form.Item>

                                <Form.Item>
                                    <Checkbox
                                        checked={checked}
                                        onChange={(e) => setChecked(e.target.checked)}
                                    >
                                        <Label fontSize={FontSizes.h4} marginTop={5} marginBottom={0}>
                                            I agree to the <a style={{ color: colors.secondaryText, textDecoration: 'underline' }}>terms of nomination for the award</a>
                                        </Label>
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={isLoading}
                                        disabled={!checked || isLoading}
                                        style={{ width: "100px", height: "45px" }}
                                    >
                                        {t('submit')}
                                    </Button>
                                </Form.Item>
                            </Form>
                            {errorAlert.open && (
                                <Alert
                                    type={errorAlert.severity}
                                    message={errorAlert.msg}
                                    style={{ marginTop: "20px" }}
                                />
                            )}
                        </motion.div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default AwardForm;
