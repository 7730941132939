// Core
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware, Store } from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import RootReducer from './reducers/rootReducer';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from 'locales/i18n';

const logger = createLogger();

// Create the Redux store with initial state and middleware
const store: Store<any> = createStore(RootReducer, applyMiddleware(thunk, logger));

// Get the root container
const container = document.getElementById('root')!; // Non-null assertion

// Create a root with the container
const root = createRoot(container);

// Wrap your App component with the Redux Provider
const reduxApp = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);

// Render the application
root.render(reduxApp);