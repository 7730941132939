import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const HeaderOuter = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 11;

    .__drawer {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
`;

export const DrawerItems = styled.div<any>`
    display: flex;
    flex-direction: column;
    gap: 5px;
    direction: ${(props) => props.direction};

    a {
        color: #000;
        font-size: 17px;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .active {
        font-weight: 600;
        color: ${colors.secondary};

        svg {
            fill: ${colors.secondary};
        }
    }

    .__border-bottom {
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    svg {
        width: 15px;
        height: 15px;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fffdf66b;
    padding: 10px 40px;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0px 0px 10px #0001;
    backdrop-filter: blur(10px);

    button {
        color: #fff;
        border: none;
        background: ${colors.darkPrimary};
        font-weight: 700;
        border-radius: 47px;
        font-size: 15px;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const TopMenu = styled.div<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @media (max-width: 556px) {
        display: none;
    }

    ${(props) =>
        props.direction === "rtl"
            ? `a {
                    font-size: 18px;
                }`
            : `a {
                    font-size: 14px;
                    letter-spacing: 0.01em;
                }`}

    .active {
        color: ${colors.secondary};
    }
`;

export const LogoText = styled.a`
    font-size: 17px;
    font-weight: 600;
    display: flex;
    gap: 10px;
    align-items: center;

    img {
        width: 50px;
    }
`;

export const RightMenu = styled.div<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    ${(props) =>
        props.direction === "rtl"
            ? `a {
                    font-size: 17px;
                }`
            : `a {
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: 0.01em;
                }`}

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        span {
            font-size: 14px;
        }
    }
`;

export const AskBtn = styled.div`
    background: linear-gradient(45deg, #c19a73, #b15921);
    height: 30px;
    border: none;
    border-radius: 6px;
    color: #ffffff !important;
    cursor: pointer;
    display: flex;
    font: inherit;
    font-size: 17px;
    font-weight: 500;
    justify-content: center;
    outline: inherit;
    padding: 13px;
    transition: 0.5s;
    white-space: nowrap;
    align-items: center;
    gap: 10px;
    width: 46px !important;
    text-shadow: 0px 0px 20px #000;
    img{
        width: 25px;
        height: 25px;
        object-fit: scale-down;
        filter: brightness(100);
    }
`