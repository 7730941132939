import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const CommonContainer = styled.div`
.___chat_container_wrapper {
    width: 100%;
    margin: auto auto;
    max-height: 85vh;
    position: relative;
    overflow: hidden;
    direction: ltr !important;

    
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px
}


/* Track */

*::-webkit-scrollbar-track {
    background: #EEEAEA;
    opacity: .7;
    border-radius: 6px
}


/* Handle */

*::-webkit-scrollbar-thumb {
    background: #0004;
    height: 25px;
    border-radius: 6px;
}


/* Handle on hover */

*::-webkit-scrollbar-thumb:hover {
    background: #777;
    transition: 0.5s;
}

    .__chat_display {
        overflow: auto;
        padding: 20px;
        border-radius: 20px;
        max-height: calc(100vh - 225px);
        margin-bottom: 5px;
        min-height: 72vh;
    }

    .___use_resp_set {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 20px;

        .___response {
            h2 {
                margin: 0;
                font-size: 18px;
            }

            p {
                margin-top: 5px;
                font-size: 18px;
                font-weight: 300;
                margin-bottom: 0;
                margin-top: 15px;
                line-height: 1.8;
            }

        }
    }

    .___user {
        color:${colors.primary};

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            max-width: max-content;
        }
    }

    .___send_form {
        width: 100%;
        display: flex;
        overflow: hidden;
        border-radius: 20px;
        background: #ccccccb8;

        .___text_area {
            overflow-y: auto;
            padding-bottom: 1.2rem;
            padding-top: 1.2rem;
            border-radius: 20px;
            padding-left: 1rem;
            padding-right: 3rem;
            resize: none;
            outline: none;
            width: auto;
            flex: 1 1;
            max-height: 140px;
            font-size: 1rem;
            border: 1px solid #eee2;
            background-color: #ffffffe8;

            &:focus {
                border-color: #eee4;
                transition: 0.4s;
            }
        }

        .___ask_btn {
            background: linear-gradient(45deg, #c19a73, #b15921);
            height: 43px;
            border: none;
            border-radius: 10px;
            color: #fff !important;
            cursor: pointer;
            display: flex;
            font: inherit;
            font-size: 17px;
            font-weight: 500;
            justify-content: center;
            outline: inherit;
            padding: 12px 18px;
            transition: 0.5s;
            white-space: nowrap;
            align-items: center;
            gap: 10px;
        }

        button:disabled {
            background: #687880;
            cursor: not-allowed;
            transition: 0.5s;
        }
    }
}



.__container_head {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 25px;

    span {
        font-size: 26px;
        font-weight: 600;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    p {
        margin: 0;
        font-size: 13px;
    }
}


.__initial_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 68vh;
    flex-direction: column;
    border-radius: 10px;

    img {
        width: 200px;
        object-fit: contain;
        filter: brightness(2) hue-rotate(-200deg);
    }

    span {
        font-size: 16px;
        font-weight: 400;
    }
}

.__container_body {
    overflow: auto;
    height: calc(100vh - 316px);
    padding-right: 15px;
}


.__common_model_class_2 {
    width: 65vw;
    margin: 0 auto;
}

.__right_btn_actions {
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.__micr {
    padding: 0 !important;
    background: none !important;
    border: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;

    img {
        width: 35px;
        height: 35px;
        object-fit: contain;
        filter: hue-rotate(180deg);
    }
}

.__loading {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-left: 55px;

    img {
        width: 27px;
        height: 27px;
    }
}


.__share {

    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 10px;
    padding: 4px 15px;
    font-weight: 600;
    width: max-content;
    font-size: 14px;
    height: 30px;
    background: #00000008;
}
`