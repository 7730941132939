import styled from 'styled-components';

export const MapContainer = styled.div`
    width: 100%;
    height: 90vh;
    position: relative;
`;

export const FsContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const LeafletContainer = styled.div`
    outline: 0;
    cursor: -moz-grab;
    overflow: hidden;
    touch-action: none;
    font-family: inherit;
    font-size: 12px;
    line-height: 1.5;
    background: #ddd;
    outline-offset: 1px;
    -webkit-tap-highlight-color: transparent;
`;