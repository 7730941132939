/* eslint-disable react-hooks/exhaustive-deps */
// Core
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import { CommonGlassContainer, H1Head, Label } from "assets/styles/globalStyles";

// Types
import {
    AwardLists,
    FounderWrapper,
    YearHead
} from "./styles";

// Components
import Loader from "components/Loader";
import Footer from "components/Footer";

// Others
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { WinningCard } from "pages/Homepage/styles";
import { FontSizes, FontWeight } from "utils/enums";
import { colors } from "assets/styles/colors";

import AOS from "aos";
import { Tooltip } from "antd";

const AwardList: React.FC = () => {
    const { currentLang } = useSelector((store: any) => store.commonData);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [pageLoading, setPageLoading] = useState(false);
    const [awards, setData] = useState([]);
    const [type, setAlignment] = useState('year');

    useEffect(() => {
        AOS.init({
            duration: 800, // Animation duration in milliseconds
            easing: 'ease-in-out', // Easing function for the animation
            once: false, // Ensures the animation runs every time the element is in view
        });

        const handleScroll = () => {
            AOS.refresh();
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    const fetchAwards = async (type = 'year') => {
        setLoading(true)
        const res = await fetchData(restAPIs.awardsByType(currentLang?.id, type));
        setData(res.awards)
        setLoading(false);
    }

    useEffect(() => {
        fetchAwards()
        window.scrollTo(0, 0);
    }, [currentLang]);

    const handleBook = (bookId: any) => {
        navigate(`/book/${bookId}`)
    }


    // if (pageLoading) return <Loader />;

    return (
        <>
            <FounderWrapper
                direction={(currentLang?.id) == 1 ? "rtl" : "ltr"}
            >
                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-11">
                            <CommonGlassContainer>
                                <motion.div
                                    style={{ marginLeft: '-8px' }}
                                    className='mb-5'
                                    initial={{ opacity: 0, x: -50 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Label className="fade-left" fontSize={FontSizes.h1} fontWeight={FontWeight.bold} marginTop={0} marginBottom={5}>Ibn Battuta Awards</Label>
                                </motion.div>

                                {
                                    isLoading ? <Loader /> :

                                        awards && awards.map((award: any, idx: any) => {
                                            return (
                                                <div className='__single_set mb-5' key={idx}>
                                                    <YearHead>
                                                        <h1 className='__sec_hd'>{award.typeName}</h1>
                                                        <button>
                                                            {t('see_all')}
                                                        </button>
                                                    </YearHead>
                                                    <AwardLists>
                                                        {
                                                            award?.winners?.map((item: any, i: any) => {
                                                                return (
                                                                    <div
                                                                        className="mb-4"
                                                                        style={{ width: '100%' }}
                                                                        key={i}
                                                                    >
                                                                        <WinningCard className="fade-left" data-aos="fade-left">
                                                                            <div className="book">
                                                                                <div className="cover" style={{ backgroundImage: `url(${item.coverImage})` }}></div>
                                                                            </div>

                                                                            <div className="__content">
                                                                                <Tooltip title={item.bookName}>
                                                                                    <Label fontWeight={FontWeight.bold} color={colors.primary} fontSize={FontSizes.h2} marginBottom={15}>
                                                                                        {item.bookName}
                                                                                    </Label>
                                                                                </Tooltip>
                                                                                <Tooltip title={`Category: ${item.categoryName}`}>
                                                                                    <p>Category : {item.categoryName}</p>
                                                                                </Tooltip>
                                                                                <Tooltip title={`Author: ${item.authorName}`}>
                                                                                    <p className="__author">Author : {item.authorName}</p>
                                                                                </Tooltip>
                                                                                <Tooltip title={`Year of Winning: ${item.year}`}>
                                                                                    <p>Year of Winning : {item.year}</p>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </WinningCard>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div className='__dummy' data-aos="fade-left">
                                                            {t('see_all')}
                                                        </div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>

                                                    </AwardLists>
                                                </div>
                                            )
                                        }
                                        )
                                }
                            </CommonGlassContainer>
                        </div>
                    </div>
                </div>
            </FounderWrapper>
        </>
    );
};

export default AwardList;

