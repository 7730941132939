import React, { useState, useEffect, useRef } from 'react';
import * as L from 'leaflet';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import { FsContainer, MapContainer } from './style';
import { H1Head } from 'assets/styles/globalStyles';

interface Location {
    latitude: number;
    longitude: number;
    [key: string]: any;
}

interface StoreState {
    commonData: {
        currentLang: {
            id: string;
        };
    };
}

const AwardMap: React.FC = () => {
    const { currentLang } = useSelector((store: StoreState) => store.commonData);
    const { t } = useTranslation();
    const [location, setLocation] = useState<Location | {}>({});
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const mapRef = useRef<L.Map | null>(null); // Ref to store the map instance

    useEffect(() => {
        fetchMap();
    }, []);

    const fetchMap = async () => {
        if (mapRef.current) return; // Prevent reinitialization

        const res = await fetchData(restAPIs.awardsByLocation(currentLang?.id));
        const map = L.map("map").setView([15.85778091911655, 52.412109375000004], 4);
        mapRef.current = map; // Store the map instance in the ref

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: '...',
        }).addTo(map);

        map.on('click', function (e) {
            console.log('map click!', e);
        });

        for (let i = 0; i < res.locations.length; i++) {
            const element = res.locations[i];
            const marker = L.marker([element.latitude, element.longitude], {
                opacity: 1,
                icon: L.icon({
                    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
                    shadowSize: [41, 41],
                    shadowAnchor: [12, 41],
                }),
            });

            marker.on('click', (e) => {
                L.DomEvent.stopPropagation(e);
                if (e) {
                    dispatch({
                        type: 'locationByBook',
                        value: res.locations[i],
                    });
                    setLocation(res.locations[i]);
                    setOpen(true);
                }
            });

            marker.addTo(map);
        }
    };

    const handleClose = () => {
        setOpen(false);
        dispatch({
            type: 'locationByBook',
            value: {},
        });
    };

    return (
        <>
            <div className='container'>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                >
                    <H1Head style={{ marginBottom: '20px' }}>{t('map')}</H1Head>
                </motion.div>
            </div>
            <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
            >
                <FsContainer>
                    <MapContainer id="map" style={{ zIndex: 0 }}></MapContainer>
                </FsContainer>
            </motion.div>
            {/* <BooksByLocationModal location={location} open={open} onClose={handleClose} /> */}
        </>
    );
};

export default AwardMap;
